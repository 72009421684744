function initCookieConsent() {

  CookieConsent.run({

    categories: {
        necessary: {
            enabled: true,  // this category is enabled by default
            readOnly: true  // this category cannot be disabled
        },
        analytics: {}
    },

    language: {
        default: 'de',
        translations: {
            de: {
                consentModal: {
                    title: 'Diese Webseite verwendet Cookies',
                    description: 'Wir verwenden Cookies, um Inhalte und Anzeigen zu personalisieren, Funktionen für soziale Medien anbieten zu können und die Zugriffe auf unsere Website zu analysieren. Außerdem geben wir Informationen zu Ihrer Verwendung unserer Website an unsere Partner für soziale Medien, Werbung und Analysen weiter. Unsere Partner führen diese Informationen möglicherweise mit weiteren Daten zusammen, die Sie ihnen bereitgestellt haben oder die sie im Rahmen Ihrer Nutzung der Dienste gesammelt haben.',
                    acceptAllBtn: 'Alle akzeptieren',
                    acceptNecessaryBtn: 'Alle ablehnen',
                    showPreferencesBtn: 'Einstellungen verwalten'
                },
                preferencesModal: {
                    title: 'Präferenzen für die Zustimmung',
                    acceptAllBtn: 'Alle akzeptieren',
                    acceptNecessaryBtn: 'Alle ablehnen',
                    savePreferencesBtn: 'Auswahl akzeptieren',
                    closeIconLabel: 'Fenster schließen',
                    sections: [
                        {
                            title: 'Verwendung von Cookies',
                            description: 'I want one!'
                        },
                        {
                            title: 'Erforderliche Cookies',
                            description: 'Notwendige Cookies helfen dabei, eine Webseite nutzbar zu machen, indem sie Grundfunktionen wie Seitennavigation und Zugriff auf sichere Bereiche der Webseite ermöglichen. Die Webseite kann ohne diese Cookies nicht richtig funktionieren.',

                            //this field will generate a toggle linked to the 'necessary' category
                            linkedCategory: 'necessary'
                        },
                        {
                            title: 'Performance und Analytics',
                            description: 'Diese Cookies sammeln Informationen darüber, wie Sie unsere Webseite nutzen. Alle Daten sind anonymisiert und können nicht dazu verwendet werden, Sie zu identifizieren',
                            linkedCategory: 'analytics'
                        },
                        {
                            title: 'Weitere Informationen',
                            description: 'Bei Fragen zu unseren Cookie-Richtlinie und Ihren Auswahlmöglichkeiten <a href="/kontakt">kontaktieren Sie uns</a> bitte.'
                        }
                    ]
                }
            }
        }
    }
});

}