function initMouseFollower() {
  if (window.matchMedia('(pointer:fine)').matches) {
    const cursor = new MouseFollower();
    const el = document.querySelector('.btn');

    el.addEventListener('mouseenter', () => {
      cursor.hide();
    });

    el.addEventListener('mouseleave', () => {
      cursor.show();
    });
  }
}