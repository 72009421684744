function initAccordion() {

  var acc = document.getElementsByClassName("accordion");
  var i;

  for (i = 0; i < acc.length; i++) {

    if (acc[i].dataset.listener == "true") {
      continue;
    } else {

      acc[i].dataset.listener = "true";
      acc[i].addEventListener("click", function () {
        this.classList.toggle("open");

        var panel = this.querySelector(".panel");

        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
        } else {
          panel.style.maxHeight = panel.scrollHeight + "px";
        }

      });
    }
    
  }
}