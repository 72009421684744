// Test JavaScript, to ensure Babel is working.

gsap.config({ nullTargetWarn: false });
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

// wait until DOM is ready (html and svg markup)
document.addEventListener("DOMContentLoaded", function (event) {

    // wait until window is loaded (images, external JS, external stylesheets, fonts, links, and other media assets)
    window.addEventListener("load", function (event) {


        // makes sure it runs after last render tick
        window.requestAnimationFrame(function () {
            initMouseFollower();
            initNavbar();
            initNavbarCollapsed();
            initPageTransition();
            initCookieConsent();
            main();
        });
        
    });
});


function main() {
    initLazyLoad();
    initTestimonialSwiper();
    initAccordion();
}