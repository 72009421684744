let menuOpen = false;
let navTl = null;

function initNavbarCollapsed() {

  // menu
  const menuBtn = document.getElementById('menu-burger');
  const b1 = document.querySelector('#menu-burger .b1');
  const b2 = document.querySelector('#menu-burger .b2');
  const overlay = document.getElementById('menu-overlay');
  const menuItems = gsap.utils.toArray('.menuItems li');
  const navbar = gsap.utils.toArray(".navbar");

  let mm = gsap.matchMedia();
  const breakPoint = 992;

  mm.add({
    isDesktop: `(min-width: ${breakPoint}px)`,
    isMobile: `(max-width: ${breakPoint}px)`,
  }, (context) => {
    let { isDesktop, isMobile } = context.conditions;

    // hide overlay
    gsap.set(overlay, {
      yPercent: isMobile ? -100 : 0,
    })

    // set menuItems
    gsap.set(menuItems, {
      opacity: isMobile ? 0 : 1
    })

    // overlay timeline
    navTl = gsap.timeline({
      defaults: {
        ease: 'power4.inOut',
        repeatRefresh: true,
      }
    });

    navTl
      .to(b1, {
        y: '-=5',
        duration: 0.3
      })
      .to(b2, {
        y: '+=5',
        duration: 0.3
      }, "-=0.3")
      .to(b1, {
        rotation: 45,
        transformOrigin: '50% 50%',
        duration: 0.4
      })
      .to(b2, {
        rotation: -45,
        transformOrigin: '50% 50%',
        duration: 0.4
      }, "-=0.4")
      .to(overlay, {
        yPercent: 0,
        duration: 1,
        opacity: 1
      }, "-=1")
      .to(menuItems, {
        opacity: 1,
        stagger: 0.1,
        duration: 0.5
      }, "-=0.1")
    navTl.pause();
  })

  // toggle menu

  menuBtn.addEventListener('click', (evt) => {
    evt.preventDefault();
    if (!menuOpen) {
      navTl.play();
      menuOpen = true;
    } else {
      navTl.reverse();
      menuOpen = false;
    }
  })

  // nav background on scroll
  var navbarTl = gsap.timeline({
    defaults: {
      ease: "easeInOut",
      repeatRefresh: true
    }
  });

  navbarTl
    .to(navbar, {
      ease: "easeInOut",
      backgroundColor: "rgba(255, 255, 255, 1)",
      duration: 0.1,
      scrollTrigger: {
        trigger: "#hero",
        start: "top top",
        end: "+=10",
        scrub: 1
      },
    },)

}