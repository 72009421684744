function initTestimonialSwiper() {
  const TestimonialSwiper = new Swiper('.testimonial-swiper', {
    // Optional parameters
    direction: 'horizontal',
    loop: false,
    spaceBetween: 8,

    // If we need pagination
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
  });
}