var dropdownOpen = false;
var dropdownTl = null;

function initNavbar() {

  const nav = document.querySelector("nav");
  const dropdownBtn = document.getElementById("btn-dropdown");
  const dropdown = document.getElementById("dropdown");



  // set dropdown
  gsap.set(dropdown, {
    display: "none",
    yPercent: -140
  })

  // set nav
  gsap.set(nav, {
    backgroundColor: "rgba(255, 255, 255, 0)",
  })

  // nav timeline background
  var navTl = gsap.timeline({
    defaults: {
      ease: "easeInOut",
      repeatRefresh: true
    }
  });

  navTl
    .to(nav, {
      ease: "easeInOut",
      backgroundColor: "rgba(255, 255, 255, 1)",
      duration: 0.1,
      scrollTrigger: {
        trigger: ".hero",
        start: "=+100",
        end: "=+200",
        scrub: 1
      },
    },)

  // dropdown timeline
  dropdownTl = gsap.timeline({
    defaults: {
      ease: "easeInOut",
      repeatRefresh: true
    }
  });

  dropdownTl
    .to(dropdown, {
      display: "block",
      duration: 0.3
    },)
    .to(dropdown, {
      yPercent: -28,
      duration: 0.3
    },)
    .reverse();


  // toggle dropdown
  dropdownBtn.addEventListener("click", (evt) => {
    evt.preventDefault();
    if (!dropdownOpen) {
      dropdownTl.play();
      dropdownOpen = true;
    } else {
      dropdownTl.reverse();
      dropdownOpen = false;
    }
  })

}