
function initPageTransition() {

  // page transition animation

  function pageTransition() {

    var tlTransition = gsap.timeline();

    tlTransition.to('.transition', {
      duration: 0.6,
      scaleY: 1,
      transformOrigin: "top",
      stagger: .2,
      scrollTo: 0,
    });

    tlTransition.to(window, { duration: 0, scrollTo: 0 });

    tlTransition.to('.transition', {
      duration: 0.5,
      scaleY: 0,
      transformOrigin: "bottom",
      stagger: .2,
      delay: .5
    });

  }

  // content animation

  function contentAnimation() {

    var tlContent = gsap.timeline();

    tlContent.to('.pageTransitionStagger', {
      ease: "expoScale(0.5,7, none)",
      duration: 0.2,
      translateY: 0,
      stagger: 0.2,
      opacity: 1
    })

  }

  function delay(n) {
    n = n || 10;
    return new Promise(done => {
      setTimeout(() => {
        done();
      }, n);
    });
  }


  // barba 

  barba.init({
    sync: true,
    debug: true,
    transitions: [{

      async leave(data) {
        const done = this.async();

        // Close mobile and desktop nav
        if (dropdownOpen) {
          dropdownTl.reverse();
          dropdownOpen = false;
          await delay(500); // Wait for dropdown to close
        }

        if (menuOpen) {
          navTl.reverse();
          menuOpen = false;
          await delay(500); // Wait for dropdown to close
        }

        // Start page transition
        pageTransition();

        // Wait some time for the animation to end
        await delay(500);

        done();
      },

      async enter(data) {
        // Update current nav item
        var nextNamespace = data.next.container.dataset.barbaName;
        updateNavActiveClass(nextNamespace);

        // Animate content
        contentAnimation();
      },

      async once(data) {
        // Update current nav item
        var nextNamespace = data.next.container.dataset.barbaName;
        updateNavActiveClass(nextNamespace);

        // Animate content
        contentAnimation();
      },

      // Ignore all links with class
      prevent: ({ el }) => el.classList && el.classList.contains('prevent-barba'),

      beforeEnter({ next }) {
        main();
      },

    }]
  })

}


function updateNavActiveClass(namespace) {
  let newNamespace;

  // Special handling for leistungen
  if (["detailing", "cleaning", "leasing"].includes(namespace)) {
    newNamespace = "leistung";
  } else {
    newNamespace = namespace;
  }

  var navItem = document.querySelector('a[data-nav-item="' + namespace + '"], button[data-nav-item="' + namespace + '"]');
  var newNavItem = document.querySelectorAll('a[data-nav-item="' + newNamespace + '"], button[data-nav-item="' + newNamespace + '"]');
  var currentActive = document.querySelectorAll('.link.active');

  // Set active nav class if none is currently present (e.g. first page visit)
  if (!currentActive) {
    navItem.classList.add('active');

  } else if (currentActive) {

    // Remove all current active class
    currentActive.forEach(item => {
      item.classList.remove('active');
    })

    // Set new active nav item
    newNavItem.forEach(item => {
      item.classList.add('active');
    });

  }

}